<!--
 * @Description: 
 * @Author: lja
 * @Date: 2021-09-18 10:47:11
 * @LastEditors: weig
 * @LastEditTime: 2022-02-23 12:19:58
-->
<template>
  <div>
    <!-- 面包屑 begin -->
    <eup-crumbs icon="Notebook" firstCrumbs="内容管理" secondCrumbs="内容详情" />
    <!-- 面包屑 end -->
    <!-- 内容区域 begin -->
    <!-- 表单内容 -->
    <div class="container">
      <!-- 查询 -->
      <div class="handle-box">
          <el-form :model="state.query" :inline="true" v-if="checkPermission([`api${state.VIEW_VERSION}:article:articleinfo:getpage`,`api${state.VIEW_VERSION}:article:articleinfo:add`,`api${state.VIEW_VERSION}:article:articleinfo:batchdelete`])" >
             <template v-if="checkPermission([`api${state.VIEW_VERSION}:article:articleinfo:getpage`])">
                  <el-form-item label="内容状态">
                  <el-select v-model="state.query.getarticlestatus" placeholder="请选择" class="handle-select mr10" @change="handleSearch">
                  <el-option
                   v-for="item in state.getarticlestatus"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value"
                      >
                    </el-option>
                     </el-select>
                   </el-form-item>
                    <el-form-item label="是否有效">
                  <el-select v-model="state.query.getisActive" placeholder="请选择" class="handle-select mr10" @change="handleSearch">
                  <el-option
                   v-for="item in state.getisActive"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value"
                      >
                    </el-option>
                     </el-select>
                   </el-form-item>
                 <el-form-item label="内容类型">
                  <el-select v-model="state.query.articleCategory" placeholder="请选择" class="handle-select mr10" @change="handleSearch">
                  <el-option
                   v-for="item in state.optionsType"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value"
                      >
                    </el-option>
                     </el-select>
                   </el-form-item>
                <el-form-item label="内容标题">
                  <el-input v-model="state.query.title" placeholder="内容标题"   @change="handleSearch">
                    <template #prefix>
                      <i class="el-input__icon el-icon-search" />
                    </template>
                  </el-input>
              </el-form-item>
               <el-form-item  label="分类名称">
                  <el-input v-model="state.query.articleTypeName" placeholder="分类名称"  class="handle-select mr10"  @change="handleSearch">
                    <template #prefix>
                      <i class="el-input__icon el-icon-search" />
                    </template>
                  </el-input>
              </el-form-item>
              <el-form-item style="margin-right:10px;">
                <el-button type="primary" icon="Search" @click="handleSearch">查询</el-button>
              </el-form-item>
               </template>
              <el-form-item style="margin-right:10px;" v-if="checkPermission([`api${state.VIEW_VERSION}:article:articleinfo:add`])">
                <el-button type="primary" icon="Plus" @click="handleClickAddData">新增</el-button>
              </el-form-item>
              <el-form-item style="margin-right:10px;">
              <el-button
                  type="danger"
                  icon="Delete"
                  class="handle-del mr10"
                  @click="handleClickBatchDelete"
                  v-if="checkPermission([`api${state.VIEW_VERSION}:article:articleinfo:batchdelete`])"
                  >批量删除
              </el-button>
              </el-form-item>
          </el-form>
      </div>

      <!-- 列表 -->
      <el-table
          border
          highlight-current-row
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          row-key="articleTypeId"
          v-loading="state.loading"
          :data="state.tableData"
          @select="onSelect"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="60" align="center"></el-table-column>
        <el-table-column prop="num" label="序号" width="60" align="center"></el-table-column>
         <el-table-column prop="title" label="内容标题"  min-width="220"  align="center" ></el-table-column>
         <el-table-column prop="articleTypeName" label="分类名称" min-width="200"  align="center" ></el-table-column>
         <el-table-column prop="articleSetting" label="内容设置" min-width="90"  align="center" :formatter="articleSetting" ></el-table-column>
         <el-table-column prop="articleStatus" label="内容状态" min-width="90"  align="center" >
           <template #default="{row}">
                  <el-tag :type="row.articleStatus == state.articleStatus.yes ? 'danger' : 'success'" disable-transitions>
                      {{ row.articleStatus == state.articleStatus.yes ? '未发布' : '已发布' }}
                  </el-tag>
              </template>
         </el-table-column>
        <el-table-column prop="isActive" label="是否有效" align="center" min-width="90" >
            <template #default="{row}">
                  <el-tag :type="row.isActive == state.isActive.yes ? 'success' : 'danger'" disable-transitions>
                      {{ row.isActive == state.isActive.yes ? '有效' : '无效' }}
                  </el-tag>
              </template>
        </el-table-column>
        <el-table-column prop="publishDate" label="发布日期" min-width="160" align="center" ></el-table-column>
          <el-table-column label="操作" min-width="240" align="center" fixed="right" v-if="checkPermission([`api${state.VIEW_VERSION}:article:articleinfo:getpage`])" >
                  <template #default="{ $index, row }">
                    <el-button
                        type="primary"
                        icon="Edit"
                        @click="handleEdit($index,row)"
                        v-if="checkPermission([`api${state.VIEW_VERSION}:article:articleinfo:update`])"
                    >编辑</el-button>
                    <el-button
                        type="danger"
                        icon="Delete"
                        @click="handleClickDelete(row)"
                        v-if="checkPermission([`api${state.VIEW_VERSION}:article:articleinfo:delete`])"
                        class="ml5"
                    >删除</el-button>
                </template>
          </el-table-column>
      </el-table>
      <!-- 分页 begin-->
       <EupPagination
          :current-page="state.pageIndex"
          :pagesizes="[10,20,50,100]"
          :pagesize="state.pageSize"
          :total="state.pageTotal"
          @getPageData="datasource"
          @resPageData="resPageData">
      </EupPagination>
      <!-- 分页 end-->
    </div>
     <!-- 内容区域 end -->
    <!-- 添加/编辑窗口 begin -->
    <el-dialog 
      :title="state.dialogTitle"
      v-model="state.addDialogFormVisible"
      width="70%"
      @close="closeEditForm()"
      :fullscreen="state.dialogFull"
      >
      <el-form
        ref="addForm"
        :model="state.form"
        :rules="state.addFormRules"
        label-width="80px"
        :inline="false"
      >
      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
       <el-form-item label="标题" prop="title">
          <el-input v-model="state.form.title" auto-complete="off"/>
        </el-form-item>
        </el-col>
      
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="内容摘要" prop="abstract">
          <el-input v-model="state.form.abstract" auto-complete="off" />
        </el-form-item>
         </el-col>
        </el-row>
        <el-row>
           <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
       <el-form-item prop="ArticleCategory" label="内容类型">    
                  <el-select v-model="state.form.ArticleCategory" placeholder="请选择"  @change="addArticleCategoryid">
                  <el-option
                   v-for="item in state.optionsType"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value"
                      >
                    </el-option>
                     </el-select>
            </el-form-item>
           </el-col>
             <el-col :xs="24" :sm="12" :md="8" :lg="16" :xl="16">
       <el-form-item prop="parentIds" label="内容分类">
          <el-cascader
            :key="state.addFormKey"
            v-model="state.form.parentIds"
            :options="state.modules"
            :props="{checkStrictly: true, value: 'articleTypeId'}"
            filterable
            style="width:100%;"
          />
        </el-form-item>
             </el-col> 
        </el-row>
         <el-row>
              <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
         <el-form-item label="内容设置" prop="articleSetting">    
                  <el-select v-model="state.form.articleSetting" placeholder="内容设置">
                  <el-option
                   v-for="item in state.optionSetting"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value"
                      >
                    </el-option>
                     </el-select>
              </el-form-item>
               </el-col>
             <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
         <el-form-item label="内容状态" prop="articleStatus">    
                  <el-select v-model="state.form.articleStatus" placeholder="内容设置">
                  <el-option
                   v-for="item in state.optionStatus"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value"
                      >
                    </el-option>
                     </el-select>
              </el-form-item>
        </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
        <el-form-item label="是否有效" prop="isActive" class="isActive" id="isActive"> 
          <el-switch v-model="state.form.isActive"/>
        </el-form-item>
        </el-col>
         </el-row>
         <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="6">
          <el-form-item label="发布时间" prop="publishDate">
           <el-date-picker
           v-model="state.form.publishDate"
           type="datetime"
           >
          </el-date-picker>
        </el-form-item>
          </el-col>
           <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="6">
         <el-form-item label="排序" prop="sequence">
          <el-input v-model="state.form.sequence" auto-complete="off" style="width: 217px"/>
        </el-form-item>
        </el-col>
        </el-row>
          <el-row>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
         <WangEditor :contant="state.form.articleContent" @updateContent="updateContent"></WangEditor>
         </el-col>
         </el-row>
      </el-form> 
      
      <template #title>
        <div class="avue-crud__dialog__header">
            <span class="el-dialog__title">
            <span style="display:inline-block;width:3px;height:20px;margin-right:5px; float: left;margin-top:2px"></span>
              {{state.dialogTitle}}
            </span>
       <div class="avue-crud__dialog__menu" @click="state.dialogFull? state.dialogFull=false: state.dialogFull=true">
            <el-icon v-if="!state.dialogFull" title="全屏"><FullScreen/></el-icon>
            <el-icon v-else title="缩小"><CopyDocument/></el-icon>
          </div> 
        </div>
      </template>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeEditForm()">取 消</el-button>
          <el-button type="primary" @click="addDialogFormSave()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 添加/编辑窗口 end -->
  </div>
</template>
<script>
import { reactive, onMounted, ref,  } from 'vue'
import { ElMessage,ElMessageBox } from 'element-plus'
import {elConfirmDialog, IsNullOrEmpty} from "@/common/js/comm"
import { GetInfoPage,addInfo,GetArticle,editInfo,removeInfo,batchRemoveInfo} from '@/serviceApi/article/articleInfo'
import { getPage} from '@/serviceApi/article/articleType'
import { treeToList, listToTree, getTreeParent} from '@/utils/tool'
import {useStore} from 'vuex'
import WangEditor from '@/components/eup-editor/WangEditor.vue'
import EupPagination from "../../../components/EupPagination.vue"
import EupCrumbs from "../../../components/eup-crumbs/index.vue"
import { formatDateTime } from "@/common/js/comm";
import EnumConfig from "@/enum/EnumConfig"

var CURR_VIEW_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `:S:${CURR_VIEW_VERSION}`;
export default {
  name: 'ArticleInfo',
  components:{
    EupPagination: EupPagination,
    EupCrumbs: EupCrumbs,
     WangEditor: WangEditor
  },
  
  setup() {
    const multipleTable = ref(null);
    const addForm = ref(null);
    const state = reactive({
      VIEW_VERSION: VIEW_VERSION,
       disabledDate(time) {
        return time.getTime() > Date.now()
      },    
      query: {
           title:'',
           articleTypeName:'',
           getarticlestatus:'0',
           getisActive:'0',
           articleCategory:1
        },  
         multipleSelection: [],                                                                                                                                                                      
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
        tableData: [],
        form: {
            articleTypeId:"",
            articleStatus:2,
            articleSetting:1,
            articleId:'',
            title: "",
            abstract: "",
            articleContent: "",
            publishDate:new Date(),
            sequence: 1,
            parentIds:[],
            ArticleCategory:1,
            isActive:true
        },
        addFormRules:{
            title: [{ required: true, message: '请输入标题', trigger: 'blur'},
            {max:50, message: '标题长度不能超过50字', trigger: 'blur'}],
            abstract: [{ required: true, message: '请输入内容摘要', trigger: 'blur'},
            {max:200, message: '内容摘要长度不能超过200字', trigger: 'blur'}],
            ArticleCategory: [{ required: true, message: '请选择内容类型', trigger: 'change' }],
            parentIds: [{ required: true, message: '请选择内容分类', trigger: ['change'] }],
            publishDate: [{ required: true, message: '请选择发布时间', trigger: ['change'],type:'date'} ],
            sequence: [ { required: true, message: '排序不能为空' ,trigger: ['blur']}],
            articleContent: [{ required: true, message: '请输入内容', trigger: ['blur'] }],
        },
          isActive: {
          yes: 1,
          no: 2
        },
        articleStatus:{
           yes: 1,
          no: 2
        },
        optionsType:[
        { value: 1, label:'文章'},
        { value: 2, label:'通知'},
        { value: 3, label:'系统帮助'}
      ],
       optionSetting:[
        { value: 1, label:'普通'},
        { value: 2, label:'置顶'},
        { value: 3, label:'推荐'},
        { value: 4, label:'热门'}
       ],
       optionStatus:[
        { value: 1, label:'未发布'},
        { value: 2, label:'已发布'},
       ],
        getarticlestatus:[
        {value:'0',label:'全部'},
        { value:'1',label:'未发布'},
        { value:'2',label:'已发布'},
       ],
        getisActive:[
        {value:'0',label:'全部'},
        { value:'1',label:'有效'},
        { value:'2',label:'无效'},
       ],
        loading: false,
        store: {}, //vuex全局状态管理对象
        addDialogFormVisible: false, //是否显示对话框
        dialogTitle: "", //对话框标题
        dialogType: 0, //对话框类型, 0:编辑框  1:新增框
        dialogFull: false, //是否为全屏 Dialog
        expandRowKeys: [],
        modules: [],
        sels: [], // 列表选中列
        articleTypeTree: [],
        addFormKey: 1,
        msg:'',
    });
   
     const articleCategory = (row) => {
      if (row.articleCategory == 1) {
        return " 文章";
      } else if (row.articleCategory == 2) {
        return " 通知";
      } else {
        return "系统帮助";
      }
    }
    const articleSetting = (row) => {
         if (row.articleSetting==1) {
           return "普通";
         }else if (row.articleSetting==2) {
           return "置顶";
         }else if (row.articleSetting==3) {
           return "推荐";
         }else {
           return "热门";
         }
    }
     /**
     * @description 同步富文本内容
     * @author lja
     */
    const updateContent = (val) => {
     state.form.articleContent = val;
  }
    onMounted(() => {
      datasource();
      /* getType(); */
      state.store = useStore();
    });

    /**
     * @description 获取表单数据
     * @author lja
     */
    const datasource = () => {
       var params = {
          "currentPage": state.pageIndex,
          "pageSize": state.pageSize,
          "Filter.Title":state.query.title,
          "Filter.ArticleTypeName":state.query.articleTypeName,
          "Filter.ArticleStatus":state.query.getarticlestatus=='0'? null:state.query.getarticlestatus,
          "Filter.ArticleCategory":state.query.articleCategory=='0'?null:state.query.articleCategory,
          "Filter.IsActive":state.query.getisActive=='0'? null:state.query.getisActive,
            }
      state.loading = true;
     GetInfoPage(params).then(function(res){
        if (res.code == 1) {
            state.pageTotal = res.data.total;//初始化列表数据总数
          let list = JSON.parse(JSON.stringify(res.data.list));
           state.tableData = list;
           state.tableData.forEach((data, i) => {
                    data.num = i + 1;
                });
        } else {
          ElMessage.error(res.msg);
        }
        state.multipleSelection= [];
       state.loading = false;
      })
      .catch(function (err) {
          ElMessage.error("调用接口失败!," + err); 
          state.loading = false;
      });
    }
     /**
     * @description 获取所选类型的内容分类
     * @author lja
     */
    const addArticleCategoryid = ()=>{
          let params={
           'Filter.ArticleCategory':state.form.ArticleCategory,
           "PageSize":10000000
          }
          getPage(params).then(function(res){
           let list = JSON.parse(JSON.stringify(res.data.list));
           list.forEach(l => {
              l._loading = false
            });
            //将articleTypeName字段名改为label，便于联级框使用
            var a=[];
               var b=[];
               for(var i = 0; i < list.length; i++){
              a.push(list[i].articleTypeName);
              var obj = {
                label: list[i].articleTypeName,
                articleTypeId:list[i].articleTypeId,
                parentId:list[i].parentId,
                articleCategory:list[i].articleCategory,
                };
                b.push(obj);
                }
              state.modules = listToTree(JSON.parse(JSON.stringify(b)),{
              articleTypeId: 0,
              parentId: 0,
              label: '顶级'
            }, "articleTypeId")
               const tree = listToTree(list, null, "articleTypeId");
              state.articleTypeTree=tree;
          })
          .catch(function (err) {
          ElMessage.error("调用接口失败!," + err);
      });
    }
    /**
     * @description 子组件返回分页数据
     * @author lja
     * @param {Object} obj
     */
    const resPageData = (obj) =>{
     state.pageIndex = obj.currPage;
      state.pageSize = obj.pageSize;
    }
    /**
     * @description 改变查询条件
     * @author lja
     * @param {String} val 值
     */
    const handleChangeQuery = (val)=>{
      //刷新列表
      datasource();
    }

    /**
     * @description 单行选中
     * @author lja
     * @param {Array} selection 当前选中行的上级父级节点
     * @param {Object} row 当前行数据
     */
    const onSelect = (selection, row) =>{
      const checked = selection.some(s => s.id === row.id);
      if (row.children && row.children.length > 0){
        const rows = treeToList(row.children);
        rows.forEach(row => {
          multipleTable.value.toggleRowSelection(row, checked);
        });
      }
      state.multipleSelection= multipleTable.value.store.states.selection.value;
    }

    /**
     * @description 查询
     * @author lja
     */
    const handleSearch =()=>{
        state.pageIndex = 1
        datasource();
    }

    /**
     * @description 新增
     * @author lja
     */
    const handleClickAddData = () =>{
      state.addDialogFormVisible = true;
      state.dialogType = 1;
      state.dialogTitle = "新增";
      state.form.ArticleCategory=state.query.articleCategory
      addArticleCategoryid();
    }
    /**
     * @description 批量删除
     * @author lja
     */
    const handleClickBatchDelete = () =>{
       if (state.multipleSelection.length == 0) {
        return ElMessage.error("请选择要删除的数据");
      }
      elConfirmDialog(
        ElMessageBox,
        "此操作将永久删除该数据, 是否继续?",
        "提示",
        "",
        () => {
          batchRemoveInfo(state.multipleSelection)
            .then((res) => {
              if (res.code == 1) {
                ElMessage.success("删除成功");
                datasource();
              } else {
                ElMessage.error("删除失败!");
              }
            })
            .catch((err) => {
              ElMessage.error(err.msg);
            });
        },
        () => {
          ElMessage.info("取消删除!");
        }
      );
    }

     const handleSelectionChange = (val) => {
      state.multipleSelection = val.map((s) => {
        return s.articleId;
      });
    };
    /**
     * @description 编辑
     * @author lja
     * @param {Number} index 行号
     * @param {Object} row 行数据
     */
    async function handleEdit (index, row){
        state.form.ArticleCategory=row.articleCategory;
         addArticleCategoryid(); 
           
      state.addDialogFormVisible = true;
      state.dialogType = 0;
      state.dialogTitle = "编辑";
        setTimeout(() => {
       let params={
         'articleId':row.articleId
       }
     GetArticle(params).then((res) => {
      if (res && res.code == 1){
        const parents = getTreeParent(state.articleTypeTree, row.articleTypeId, "children", "articleTypeId");
        const parentIds = parents.map(p => p.articleTypeId);
        parentIds.unshift(0);
        const data = res.data;
        data.parentIds = parentIds;
        state.form.parentIds = data.parentIds;
        state.form.abstract = data.abstract;
        state.form.isActive = data.isActive == state.isActive.yes ? true : false;
        var Base64 = require('js-base64').Base64;
        var articleContent= Base64.decode(data.articleContent);
        state.form.articleContent = articleContent;
        state.form.articleSetting = data.articleSetting;
        state.form.articleStatus=data.articleStatus; 
        state.form.articleCategory=data.articleCategory;
        state.form.publishDate=data.publishDate;
        state.form.sequence=data.sequence;
        state.form.title=data.title;
        state.form.articleId=row.articleId
        ++state.addFormKey;
      } else {
        ElMessage.error(res.msg)
      }})
        },
            100);
    }

    /**
     * @description 删除
     * @author lja
     * @param {Object} row 行数据
     */
    const handleClickDelete = (row) =>{
       var id = row.articleId;
      elConfirmDialog(ElMessageBox,'此操作将永久删除该数据, 是否继续?','提示', '', ()=>{
        removeInfo(id).then(res =>{
          if(res.code == 1){
              ElMessage.success("删除成功!");
              datasource();
          } else {
              ElMessage.error(res.msg);
          }
        });
      },);
    }

    /**
     * @description 确认保存
     * @author lja
     */
    const addDialogFormSave = ()=>{
       if(IsNullOrEmpty(state.form.sequence)){
        ElMessage.error("排序不能为空!");
        return;
      }
       if(IsNullOrEmpty(state.form.title)){
        ElMessage.error("标题不能为空!");
        return;
      }
      if(IsNullOrEmpty(state.form.articleContent)){
        ElMessage.error("内容不能为空!");
        return;
      }
       if(IsNullOrEmpty(state.form.abstract)){
        ElMessage.error("摘要不能为空!");
        return;
      }
       if(IsNullOrEmpty(state.form.publishDate)){
        ElMessage.error("发布日期不能为空!");
        return;
      }
      const copyData = JSON.parse(JSON.stringify(state.form))
       const parentId = copyData.parentIds.pop();
       if (parentId==null){
        ElMessage.error("内容分类不能为空!");
        return;
      }
      if(parentId==0){
        ElMessage.error("内容分类不能为顶级!");
        return;
      }
      
      let params = {};
      switch (state.dialogType){
        case 0://编辑
         var Base64 = require('js-base64').Base64;
              var articleContent= Base64.encode(state.form.articleContent);
            params = {
              "articleTypeId":parentId,
              "articleStatus": state.form.articleStatus,
              "articleSetting": state.form.articleSetting,
              "isActive": state.form.isActive ? state.isActive.yes : state.isActive.no,
              "sequence": state.form.sequence,
              "articleContent":articleContent,
              "abstract": state.form.abstract,
              "title": state.form.title,
              "articleId":state.form.articleId,
              "publishDate":formatDateTime(state.form.publishDate,"yyyy-MM-dd HH:mm:ss")
            } 
            editInfo(params).then(res =>{
                if(res.code == 1){
                    ElMessage.success("修改成功");
                    state.addDialogFormVisible = false;
                    datasource();
                } else {
                    ElMessage.error(res.msg);
                }
            });
            break;
        case 1://新增
         var Base64 = require('js-base64').Base64;
              var articleContent= Base64.encode(state.form.articleContent);
              params = {
              "articleTypeId":parentId,
              "articleStatus": state.form.articleStatus ,
              "articleSetting": state.form.articleSetting ,
              "isActive": state.form.isActive ? state.isActive.yes : state.isActive.no,
              "sequence": state.form.sequence,
              "articleContent":articleContent,
              "abstract": state.form.abstract,
              "title": state.form.title ,
              "publishDate":state.form.publishDate
              }
              addInfo(params).then(res =>{
                  if(res.code == 1){
                      ElMessage.success("添加成功");
                      state.addDialogFormVisible=false;
                      datasource();
                  } else {
                      ElMessage.error(res.msg);
                  }
              });
              break;
        default:
          break;
      }
    }

   /**
     * @description 关闭对话框
     * @author lja
     */
    const closeEditForm = ()=>{
        state.addDialogFormVisible = false;
         addForm.value.resetFields();//清空表单数据
        state.dialogType = 0;
        state.articleTypeTree=[];
        ++state.addFormKey;
        state.form={
             articleTypeId:"",
            articleStatus:2,
            ArticleCategory:1,
            articleSetting:1,
            parentIds:[],
            title: "",
            abstract: "",
            articleContent: "",
            publishDate:new Date(),
            sequence: 1,
            isActive:true
        };
    }
    return {
      state,
      handleSearch,
      handleClickAddData,
      handleClickBatchDelete,
      handleEdit,
      handleClickDelete,
      datasource,
      articleCategory,
      handleChangeQuery,
      multipleTable,
      addForm,
      onSelect,
      addArticleCategoryid,
      closeEditForm,
      articleSetting,
      addDialogFormSave,
      resPageData,
      updateContent,
      handleSelectionChange
  }
}
}

</script>
<style>
</style>